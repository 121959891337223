/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useState } from "react";
import { Avatar_02, Avatar_16 } from "../../../Routes/ImagePath";
import { Link, useParams } from "react-router-dom";
import ProfileTab from "./ProfileTab";
import Breadcrumbs from "../../../components/Breadcrumbs";
import axios from "axios";
import { number } from "yup";
import moment from 'moment';

const Profile = () => {
  const { id } = useParams()
  console.log(id);
  const [employeedata, setEmployeedata] = useState()
  const [selectedUser, setSelectedUser] = useState(null);
  const getEmployeeData = async () => {
    await axios
      .get(`https://hr.tanaghomtech.com/portal/public/api/employee/${id}`)
      .then((response) => {
        setEmployeedata(response.data.data);
        console.log(response.data.data);


      });
  };

  useEffect(() => {
    getEmployeeData()
  }, []);

  console.log(employeedata);

  const userData = {
    id: 1,
    name: "John Doe",
    role: "UI/UX Design Team",
    jobTitle: "Web Designer",
    employeeId: "FT-0001",
    dateOfJoin: "1st Jan 2023",
    phone: "9876543210",
    email: "johndoe@example.com",
    birthday: "24th July",
    address: "1861 Bayonne Ave, Manchester Township, NJ, 08759",
    gender: "Male",
    supervisor: {
      name: "Jeffery Lalor",
      avatar: "assets/img/profiles/avatar-16.jpg",
    },

  };
  return (
    <>
      <div className="page-wrapper">
        <div className="content container-fluid">

          <div className="card mb-0">
            <div className="card-body">
              <div className="row">
            
                <div className="col-md-12">
                  <div className="profile-view">
                    <div className="profile-img-wrap">
                      <div className="profile-img">
                        <Link to="#">
                          <img src={`https://hr.tanaghomtech.com/portal/storage/app/public/${employeedata?.image}`} alt="User Image" />
                        </Link>
                      </div>
                        
                    </div>
                    
                    <div className="profile-basic">
                      <div className="row">
                        <div className="col-md-5">
                          <div style={{ marginTop: "20px" }} className="profile-info-left">
                            <h3 className="user-name m-t-0 mb-0">
                              {employeedata?.first_name} {employeedata?.father_name} {employeedata?.last_name}
                            </h3>
                            <h6 className="text-muted">{employeedata?.job_current.name}</h6>
                           
                            <div className="small doj text-muted">
                              Date of Join : {employeedata?.hire_date}
                            </div>

                          </div>
                         
                              
                           
                        </div>
                        <div className="col-md-7">
                          <ul className="personal-info">
                            <li>
                              <div className="title">Company name:</div>
                              <div className="text">{employeedata?.company.companyName}</div>
                            </li>
                            <li>
                              <div className="title">Phone:</div>
                              <div className="text">
                                <Link to={`tel:${employeedata?.phone}`}>
                                  {employeedata?.phone}
                                </Link>
                              </div>
                            </li>
                            <li>
                              <div className="title">Email:</div>
                              <div className="text">
                                <Link to={`mailto:${employeedata?.email}`}>
                                  {employeedata?.email}
                                </Link>
                              </div>
                            </li>
                            <li>
                              <div className="title">Mobile:</div>
                              <div className="text">{employeedata?.mobile}</div>
                            </li>
                            <li>
                              <div className="title">Labor card date:</div>
                              <div className="text">{employeedata?.hire_date}</div>
                            </li>
                            <li>
                              <div className="title">Labor card expiry:</div>
                              <div className="text">{employeedata?.end_date}</div>
                            </li>
                           
                            <li>
                              <div className="title">Accommodation name:</div>
                              <div className="text">{employeedata?.address_incompany_id?.address_name}</div>
                            </li>
                            
                          

                            <li>
                              <div className="title">Location:</div>
                              <div className="text">{employeedata?.company.location}</div>
                            </li>
                            <li>
                              <div className="title">Website:</div>
                              <div className="text">{employeedata?.company.website}</div>
                            </li>

                            <li>
                              <div className="title">Current Department:</div>
                              <div className="text">{employeedata?.department_current.name}</div>
                            </li>
                            <li>
                              <div className="title">Hire Department:</div>
                              <div className="text">{employeedata?.department_hire.name}</div>
                            </li>
                            <li>
                              <div className="title">Nationality:</div>
                              <div className="text" >{employeedata?.nationality}</div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  
                  
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="card tab-box">
            <div className="row user-tabs">
              <div className="col-lg-12 col-md-12 col-sm-12 line-tabs">
                <ul className="nav nav-tabs nav-tabs-bottom">
                  <li className="nav-item">
                    <Link
                      to="#emp_profile"
                      data-bs-toggle="tab"
                      className="nav-link active"
                    >
                      Profile
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="#emp_projects"
                      data-bs-toggle="tab"
                      className="nav-link"
                    >
                      Projects
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="#bank_statutory"
                      data-bs-toggle="tab"
                      className="nav-link"
                    >
                      Bank &amp; Statutory
                      <small className="text-danger ms-1">(Admin Only)</small>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="#emp_assets"
                      data-bs-toggle="tab"
                      className="nav-link"
                    >
                      Assets
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div> */}
          {/* Profile Info Tab */}
          <ProfileTab employeedata={employeedata} />
        </div>
      </div>
    </>
  );
};

export default Profile;
